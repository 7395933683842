<script setup lang="ts"></script>

<template>
  <svg
    class="size-5"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    version="1.1"
    id="Layer_1"
    width="198.13"
    height="197.58"
    viewBox="0 0 198.13 197.58"
    overflow="visible"
    enable-background="new 0 0 198.13 197.58"
    xml:space="preserve"
    inkscape:version="0.48.0 r9654"
    sodipodi:docname="Logo-Ruby.svg"
  >
    <metadata id="metadata3320">
      <rdf:RDF
        ><cc:Work rdf:about=""
          ><dc:format>image/svg+xml</dc:format
          ><dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /></cc:Work
      ></rdf:RDF>
    </metadata>
    <defs id="defs3489">
      <linearGradient
        id="XMLID_17_-9"
        gradientUnits="userSpaceOnUse"
        x1="174.0737"
        y1="215.5488"
        x2="132.27631"
        y2="141.7533"
      >
        <stop offset="0" style="stop-color: #fb7655" id="stop3272-4" />
        <stop offset="0" style="stop-color: #fb7655" id="stop3274-5" />
        <stop offset="0.41" style="stop-color: #e42b1e" id="stop3276-1" />
        <stop offset="0.99" style="stop-color: #990000" id="stop3278-0" />
        <stop offset="1" style="stop-color: #990000" id="stop3280-3" />
      </linearGradient>
      <linearGradient
        id="XMLID_18_-7"
        gradientUnits="userSpaceOnUse"
        x1="194.895"
        y1="153.5576"
        x2="141.0276"
        y2="117.4093"
      >
        <stop offset="0" style="stop-color: #871101" id="stop3285-8" />
        <stop offset="0" style="stop-color: #871101" id="stop3287-8" />
        <stop offset="0.99" style="stop-color: #911209" id="stop3289-6" />
        <stop offset="1" style="stop-color: #911209" id="stop3291-0" />
      </linearGradient>
      <linearGradient
        id="XMLID_19_-4"
        gradientUnits="userSpaceOnUse"
        x1="151.79539"
        y1="217.7852"
        x2="97.929703"
        y2="181.638"
      >
        <stop offset="0" style="stop-color: #871101" id="stop3296-6" />
        <stop offset="0" style="stop-color: #871101" id="stop3298-7" />
        <stop offset="0.99" style="stop-color: #911209" id="stop3300-6" />
        <stop offset="1" style="stop-color: #911209" id="stop3302-0" />
      </linearGradient>
      <linearGradient
        id="XMLID_20_-9"
        gradientUnits="userSpaceOnUse"
        x1="38.696301"
        y1="127.3906"
        x2="47.046902"
        y2="181.66141"
      >
        <stop offset="0" style="stop-color: #ffffff" id="stop3307-7" />
        <stop offset="0" style="stop-color: #ffffff" id="stop3309-5" />
        <stop offset="0.23" style="stop-color: #e57252" id="stop3311-9" />
        <stop offset="0.46" style="stop-color: #de3b20" id="stop3313-7" />
        <stop offset="0.99" style="stop-color: #a60003" id="stop3315-8" />
        <stop offset="1" style="stop-color: #a60003" id="stop3317-5" />
      </linearGradient>
      <linearGradient
        id="XMLID_21_-3"
        gradientUnits="userSpaceOnUse"
        x1="96.132797"
        y1="76.715302"
        x2="99.209602"
        y2="132.1021"
      >
        <stop offset="0" style="stop-color: #ffffff" id="stop3322-3" />
        <stop offset="0" style="stop-color: #ffffff" id="stop3324-8" />
        <stop offset="0.23" style="stop-color: #e4714e" id="stop3326-3" />
        <stop offset="0.56" style="stop-color: #be1a0d" id="stop3328-7" />
        <stop offset="0.99" style="stop-color: #a80d00" id="stop3330-9" />
        <stop offset="1" style="stop-color: #a80d00" id="stop3332-3" />
      </linearGradient>
      <linearGradient
        id="XMLID_22_-7"
        gradientUnits="userSpaceOnUse"
        x1="147.103"
        y1="25.521"
        x2="156.3141"
        y2="65.216202"
      >
        <stop offset="0" style="stop-color: #ffffff" id="stop3337-8" />
        <stop offset="0" style="stop-color: #ffffff" id="stop3339-7" />
        <stop offset="0.18" style="stop-color: #e46342" id="stop3341-4" />
        <stop offset="0.4" style="stop-color: #c82410" id="stop3343-1" />
        <stop offset="0.99" style="stop-color: #a80d00" id="stop3345-9" />
        <stop offset="1" style="stop-color: #a80d00" id="stop3347-0" />
      </linearGradient>
      <linearGradient
        id="XMLID_23_-9"
        gradientUnits="userSpaceOnUse"
        x1="118.9761"
        y1="11.5415"
        x2="158.66859"
        y2="-8.3048"
      >
        <stop offset="0" style="stop-color: #ffffff" id="stop3352-8" />
        <stop offset="0" style="stop-color: #ffffff" id="stop3354-8" />
        <stop offset="0.54" style="stop-color: #c81f11" id="stop3356-5" />
        <stop offset="0.99" style="stop-color: #bf0905" id="stop3358-8" />
        <stop offset="1" style="stop-color: #bf0905" id="stop3360-4" />
      </linearGradient>
      <linearGradient
        id="XMLID_24_-3"
        gradientUnits="userSpaceOnUse"
        x1="3.9033"
        y1="113.5547"
        x2="7.1701999"
        y2="146.2628"
      >
        <stop offset="0" style="stop-color: #ffffff" id="stop3365-7" />
        <stop offset="0" style="stop-color: #ffffff" id="stop3367-1" />
        <stop offset="0.31" style="stop-color: #de4024" id="stop3369-3" />
        <stop offset="0.99" style="stop-color: #bf190b" id="stop3371-8" />
        <stop offset="1" style="stop-color: #bf190b" id="stop3373-0" />
      </linearGradient>
      <linearGradient
        id="XMLID_25_-9"
        gradientUnits="userSpaceOnUse"
        x1="-18.5557"
        y1="155.10451"
        x2="135.0152"
        y2="-2.8092999"
      >
        <stop offset="0" style="stop-color: #bd0012" id="stop3380-7" />
        <stop offset="0" style="stop-color: #bd0012" id="stop3382-9" />
        <stop offset="0.07" style="stop-color: #ffffff" id="stop3384-9" />
        <stop offset="0.17" style="stop-color: #ffffff" id="stop3386-3" />
        <stop offset="0.27" style="stop-color: #c82f1c" id="stop3388-2" />
        <stop offset="0.33" style="stop-color: #820c01" id="stop3390-4" />
        <stop offset="0.46" style="stop-color: #a31601" id="stop3392-3" />
        <stop offset="0.72" style="stop-color: #b31301" id="stop3394-7" />
        <stop offset="0.99" style="stop-color: #e82609" id="stop3396-1" />
        <stop offset="1" style="stop-color: #e82609" id="stop3398-2" />
      </linearGradient>
      <linearGradient
        id="XMLID_26_-2"
        gradientUnits="userSpaceOnUse"
        x1="99.074699"
        y1="171.0332"
        x2="52.817699"
        y2="159.61659"
      >
        <stop offset="0" style="stop-color: #8c0c01" id="stop3403-0" />
        <stop offset="0" style="stop-color: #8c0c01" id="stop3405-2" />
        <stop offset="0.54" style="stop-color: #990c00" id="stop3407-1" />
        <stop offset="0.99" style="stop-color: #a80d0e" id="stop3409-7" />
        <stop offset="1" style="stop-color: #a80d0e" id="stop3411-5" />
      </linearGradient>
      <linearGradient
        id="XMLID_27_-1"
        gradientUnits="userSpaceOnUse"
        x1="178.52589"
        y1="115.5146"
        x2="137.43269"
        y2="78.683998"
      >
        <stop offset="0" style="stop-color: #7e110b" id="stop3416-7" />
        <stop offset="0" style="stop-color: #7e110b" id="stop3418-4" />
        <stop offset="0.99" style="stop-color: #9e0c00" id="stop3420-1" />
        <stop offset="1" style="stop-color: #9e0c00" id="stop3422-7" />
      </linearGradient>
      <linearGradient
        id="XMLID_28_-1"
        gradientUnits="userSpaceOnUse"
        x1="193.6235"
        y1="47.937"
        x2="173.15421"
        y2="26.053801"
      >
        <stop offset="0" style="stop-color: #79130d" id="stop3427-1" />
        <stop offset="0" style="stop-color: #79130d" id="stop3429-1" />
        <stop offset="0.99" style="stop-color: #9e120b" id="stop3431-7" />
        <stop offset="1" style="stop-color: #9e120b" id="stop3433-0" />
      </linearGradient>
      <radialGradient id="XMLID_29_-4" cx="143.8315" cy="79.388199" r="50.357601" gradientUnits="userSpaceOnUse">
        <stop offset="0" style="stop-color: #a80d00" id="stop3440-0" />
        <stop offset="0" style="stop-color: #a80d00" id="stop3442-8" />
        <stop offset="0.99" style="stop-color: #7e0e08" id="stop3444-5" />
        <stop offset="1" style="stop-color: #7e0e08" id="stop3446-1" />
      </radialGradient>
      <radialGradient id="XMLID_30_-6" cx="74.0923" cy="145.75101" r="66.943703" gradientUnits="userSpaceOnUse">
        <stop offset="0" style="stop-color: #a30c00" id="stop3451-6" />
        <stop offset="0" style="stop-color: #a30c00" id="stop3453-2" />
        <stop offset="0.99" style="stop-color: #800e08" id="stop3455-1" />
        <stop offset="1" style="stop-color: #800e08" id="stop3457-9" />
      </radialGradient>
      <linearGradient
        id="XMLID_31_-6"
        gradientUnits="userSpaceOnUse"
        x1="26.669901"
        y1="197.33591"
        x2="9.9886999"
        y2="140.742"
      >
        <stop offset="0" style="stop-color: #8b2114" id="stop3462-4" />
        <stop offset="0" style="stop-color: #8b2114" id="stop3464-8" />
        <stop offset="0.43" style="stop-color: #9e100a" id="stop3466-0" />
        <stop offset="0.99" style="stop-color: #b3100c" id="stop3468-8" />
        <stop offset="1" style="stop-color: #b3100c" id="stop3470-1" />
      </linearGradient>
      <linearGradient
        id="XMLID_32_-0"
        gradientUnits="userSpaceOnUse"
        x1="154.6411"
        y1="9.7979002"
        x2="192.039"
        y2="26.305901"
      >
        <stop offset="0" style="stop-color: #b31000" id="stop3475-2" />
        <stop offset="0" style="stop-color: #b31000" id="stop3477-2" />
        <stop offset="0.44" style="stop-color: #910f08" id="stop3479-9" />
        <stop offset="0.99" style="stop-color: #791c12" id="stop3481-7" />
        <stop offset="1" style="stop-color: #791c12" id="stop3483-5" />
      </linearGradient>
      <linearGradient
        y2="141.7533"
        x2="132.27631"
        y1="215.5488"
        x1="174.0737"
        gradientUnits="userSpaceOnUse"
        id="linearGradient3448"
        xlink:href="#XMLID_17_-9"
        inkscape:collect="always"
      />
    </defs>
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1036"
      inkscape:window-height="1124"
      id="namedview3487"
      showgrid="false"
      inkscape:zoom="1.3412289"
      inkscape:cx="99.065005"
      inkscape:cy="98.790004"
      inkscape:window-x="882"
      inkscape:window-y="24"
      inkscape:window-maximized="0"
      inkscape:current-layer="Layer_1"
    />
    <linearGradient
      id="XMLID_17_"
      gradientUnits="userSpaceOnUse"
      x1="174.0737"
      y1="215.5488"
      x2="132.2763"
      y2="141.7533"
    >
      <stop offset="0" style="stop-color: #fb7655" id="stop3272" />
      <stop offset="0" style="stop-color: #fb7655" id="stop3274" />
      <stop offset="0.41" style="stop-color: #e42b1e" id="stop3276" />
      <stop offset="0.99" style="stop-color: #990000" id="stop3278" />
      <stop offset="1" style="stop-color: #990000" id="stop3280" />
    </linearGradient>

    <linearGradient
      id="XMLID_18_"
      gradientUnits="userSpaceOnUse"
      x1="194.895"
      y1="153.5576"
      x2="141.0276"
      y2="117.4093"
    >
      <stop offset="0" style="stop-color: #871101" id="stop3285" />
      <stop offset="0" style="stop-color: #871101" id="stop3287" />
      <stop offset="0.99" style="stop-color: #911209" id="stop3289" />
      <stop offset="1" style="stop-color: #911209" id="stop3291" />
    </linearGradient>

    <linearGradient id="XMLID_19_" gradientUnits="userSpaceOnUse" x1="151.7954" y1="217.7852" x2="97.9297" y2="181.638">
      <stop offset="0" style="stop-color: #871101" id="stop3296" />
      <stop offset="0" style="stop-color: #871101" id="stop3298" />
      <stop offset="0.99" style="stop-color: #911209" id="stop3300" />
      <stop offset="1" style="stop-color: #911209" id="stop3302" />
    </linearGradient>

    <linearGradient id="XMLID_20_" gradientUnits="userSpaceOnUse" x1="38.6963" y1="127.3906" x2="47.0469" y2="181.6614">
      <stop offset="0" style="stop-color: #ffffff" id="stop3307" />
      <stop offset="0" style="stop-color: #ffffff" id="stop3309" />
      <stop offset="0.23" style="stop-color: #e57252" id="stop3311" />
      <stop offset="0.46" style="stop-color: #de3b20" id="stop3313" />
      <stop offset="0.99" style="stop-color: #a60003" id="stop3315" />
      <stop offset="1" style="stop-color: #a60003" id="stop3317" />
    </linearGradient>

    <linearGradient id="XMLID_21_" gradientUnits="userSpaceOnUse" x1="96.1328" y1="76.7153" x2="99.2096" y2="132.1021">
      <stop offset="0" style="stop-color: #ffffff" id="stop3322" />
      <stop offset="0" style="stop-color: #ffffff" id="stop3324" />
      <stop offset="0.23" style="stop-color: #e4714e" id="stop3326" />
      <stop offset="0.56" style="stop-color: #be1a0d" id="stop3328" />
      <stop offset="0.99" style="stop-color: #a80d00" id="stop3330" />
      <stop offset="1" style="stop-color: #a80d00" id="stop3332" />
    </linearGradient>

    <linearGradient id="XMLID_22_" gradientUnits="userSpaceOnUse" x1="147.103" y1="25.521" x2="156.3141" y2="65.2162">
      <stop offset="0" style="stop-color: #ffffff" id="stop3337" />
      <stop offset="0" style="stop-color: #ffffff" id="stop3339" />
      <stop offset="0.18" style="stop-color: #e46342" id="stop3341" />
      <stop offset="0.4" style="stop-color: #c82410" id="stop3343" />
      <stop offset="0.99" style="stop-color: #a80d00" id="stop3345" />
      <stop offset="1" style="stop-color: #a80d00" id="stop3347" />
    </linearGradient>

    <linearGradient id="XMLID_23_" gradientUnits="userSpaceOnUse" x1="118.9761" y1="11.5415" x2="158.6686" y2="-8.3048">
      <stop offset="0" style="stop-color: #ffffff" id="stop3352" />
      <stop offset="0" style="stop-color: #ffffff" id="stop3354" />
      <stop offset="0.54" style="stop-color: #c81f11" id="stop3356" />
      <stop offset="0.99" style="stop-color: #bf0905" id="stop3358" />
      <stop offset="1" style="stop-color: #bf0905" id="stop3360" />
    </linearGradient>

    <linearGradient id="XMLID_24_" gradientUnits="userSpaceOnUse" x1="3.9033" y1="113.5547" x2="7.1702" y2="146.2628">
      <stop offset="0" style="stop-color: #ffffff" id="stop3365" />
      <stop offset="0" style="stop-color: #ffffff" id="stop3367" />
      <stop offset="0.31" style="stop-color: #de4024" id="stop3369" />
      <stop offset="0.99" style="stop-color: #bf190b" id="stop3371" />
      <stop offset="1" style="stop-color: #bf190b" id="stop3373" />
    </linearGradient>

    <linearGradient
      id="XMLID_25_"
      gradientUnits="userSpaceOnUse"
      x1="-18.5557"
      y1="155.1045"
      x2="135.0152"
      y2="-2.8093"
    >
      <stop offset="0" style="stop-color: #bd0012" id="stop3380" />
      <stop offset="0" style="stop-color: #bd0012" id="stop3382" />
      <stop offset="0.07" style="stop-color: #ffffff" id="stop3384" />
      <stop offset="0.17" style="stop-color: #ffffff" id="stop3386" />
      <stop offset="0.27" style="stop-color: #c82f1c" id="stop3388" />
      <stop offset="0.33" style="stop-color: #820c01" id="stop3390" />
      <stop offset="0.46" style="stop-color: #a31601" id="stop3392" />
      <stop offset="0.72" style="stop-color: #b31301" id="stop3394" />
      <stop offset="0.99" style="stop-color: #e82609" id="stop3396" />
      <stop offset="1" style="stop-color: #e82609" id="stop3398" />
    </linearGradient>

    <linearGradient id="XMLID_26_" gradientUnits="userSpaceOnUse" x1="99.0747" y1="171.0332" x2="52.8177" y2="159.6166">
      <stop offset="0" style="stop-color: #8c0c01" id="stop3403" />
      <stop offset="0" style="stop-color: #8c0c01" id="stop3405" />
      <stop offset="0.54" style="stop-color: #990c00" id="stop3407" />
      <stop offset="0.99" style="stop-color: #a80d0e" id="stop3409" />
      <stop offset="1" style="stop-color: #a80d0e" id="stop3411" />
    </linearGradient>

    <linearGradient id="XMLID_27_" gradientUnits="userSpaceOnUse" x1="178.5259" y1="115.5146" x2="137.4327" y2="78.684">
      <stop offset="0" style="stop-color: #7e110b" id="stop3416" />
      <stop offset="0" style="stop-color: #7e110b" id="stop3418" />
      <stop offset="0.99" style="stop-color: #9e0c00" id="stop3420" />
      <stop offset="1" style="stop-color: #9e0c00" id="stop3422" />
    </linearGradient>

    <linearGradient id="XMLID_28_" gradientUnits="userSpaceOnUse" x1="193.6235" y1="47.937" x2="173.1542" y2="26.0538">
      <stop offset="0" style="stop-color: #79130d" id="stop3427" />
      <stop offset="0" style="stop-color: #79130d" id="stop3429" />
      <stop offset="0.99" style="stop-color: #9e120b" id="stop3431" />
      <stop offset="1" style="stop-color: #9e120b" id="stop3433" />
    </linearGradient>

    <radialGradient id="XMLID_29_" cx="143.8315" cy="79.3882" r="50.3576" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color: #a80d00" id="stop3440" />
      <stop offset="0" style="stop-color: #a80d00" id="stop3442" />
      <stop offset="0.99" style="stop-color: #7e0e08" id="stop3444" />
      <stop offset="1" style="stop-color: #7e0e08" id="stop3446" />
    </radialGradient>

    <radialGradient id="XMLID_30_" cx="74.0923" cy="145.751" r="66.9437" gradientUnits="userSpaceOnUse">
      <stop offset="0" style="stop-color: #a30c00" id="stop3451" />
      <stop offset="0" style="stop-color: #a30c00" id="stop3453" />
      <stop offset="0.99" style="stop-color: #800e08" id="stop3455" />
      <stop offset="1" style="stop-color: #800e08" id="stop3457" />
    </radialGradient>

    <linearGradient id="XMLID_31_" gradientUnits="userSpaceOnUse" x1="26.6699" y1="197.3359" x2="9.9887" y2="140.742">
      <stop offset="0" style="stop-color: #8b2114" id="stop3462" />
      <stop offset="0" style="stop-color: #8b2114" id="stop3464" />
      <stop offset="0.43" style="stop-color: #9e100a" id="stop3466" />
      <stop offset="0.99" style="stop-color: #b3100c" id="stop3468" />
      <stop offset="1" style="stop-color: #b3100c" id="stop3470" />
    </linearGradient>

    <linearGradient id="XMLID_32_" gradientUnits="userSpaceOnUse" x1="154.6411" y1="9.7979" x2="192.039" y2="26.3059">
      <stop offset="0" style="stop-color: #b31000" id="stop3475" />
      <stop offset="0" style="stop-color: #b31000" id="stop3477" />
      <stop offset="0.44" style="stop-color: #910f08" id="stop3479" />
      <stop offset="0.99" style="stop-color: #791c12" id="stop3481" />
      <stop offset="1" style="stop-color: #791c12" id="stop3483" />
    </linearGradient>

    <polygon
      style="fill: url(#linearGradient3448); fill-rule: evenodd"
      clip-rule="evenodd"
      points="153.5,130.41 40.38,197.58 186.849,187.641 198.13,39.95 "
      id="polygon3282"
    />
    <polygon
      style="fill: url(#XMLID_18_-7); fill-rule: evenodd"
      clip-rule="evenodd"
      points="187.089,187.54 174.5,100.65 140.209,145.93 "
      id="polygon3293"
    />
    <polygon
      style="fill: url(#XMLID_19_-4); fill-rule: evenodd"
      clip-rule="evenodd"
      points="187.259,187.54 95.03,180.3 40.87,197.391 "
      id="polygon3304"
    />
    <polygon
      style="fill: url(#XMLID_20_-9); fill-rule: evenodd"
      clip-rule="evenodd"
      points="41,197.41 64.04,121.93 13.34,132.771 "
      id="polygon3319"
    />
    <polygon
      style="fill: url(#XMLID_21_-3); fill-rule: evenodd"
      clip-rule="evenodd"
      points="140.2,146.18 119,63.14 58.33,120.01 "
      id="polygon3334"
    />
    <polygon
      style="fill: url(#XMLID_22_-7); fill-rule: evenodd"
      clip-rule="evenodd"
      points="193.32,64.31 135.97,17.47 120,69.1 "
      id="polygon3349"
    />
    <polygon
      style="fill: url(#XMLID_23_-9); fill-rule: evenodd"
      clip-rule="evenodd"
      points="166.5,0.77 132.77,19.41 111.49,0.52 "
      id="polygon3362"
    />
    <polygon
      style="fill: url(#XMLID_24_-3); fill-rule: evenodd"
      clip-rule="evenodd"
      points="0,158.09 14.13,132.32 2.7,101.62 "
      id="polygon3375"
    />
    <path
      style="fill: #ffffff; fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 1.94,100.65 11.5,32.62 49.97,-11.211 57.05,-53.02 L 136.56,17.9 111.209,0 68.109,16.13 C 54.53,28.76 28.18,53.75 27.23,54.22 26.29,54.7 9.83,85.81 1.94,100.65 z"
      id="path3377"
    />
    <path
      style="fill: url(#XMLID_25_-9); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 42.32,42.05 c 29.43,-29.18 67.37,-46.42 81.93,-31.73 14.551,14.69 -0.88,50.39 -30.31,79.56 -29.43,29.17 -66.9,47.36 -81.45,32.67 -14.56,-14.68 0.4,-51.33 29.83,-80.5 z"
      id="path3400"
    />
    <path
      style="fill: url(#XMLID_26_-2); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 41,197.38 22.86,-75.72 75.92,24.39 C 112.33,171.79 81.8,193.55 41,197.38 z"
      id="path3413"
    />
    <path
      style="fill: url(#XMLID_27_-1); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 120.56,68.89 19.49,77.2 C 162.98,121.98 183.56,96.06 193.639,64 l -73.079,4.89 z"
      id="path3424"
    />
    <path
      style="fill: url(#XMLID_28_-1); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 193.44,64.39 c 7.8,-23.54 9.6,-57.31 -27.181,-63.58 l -30.18,16.67 57.361,46.91 z"
      id="path3435"
    />
    <path
      style="fill: #9e1209; fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 0,157.75 c 1.08,38.851 29.11,39.43 41.05,39.771 L 13.47,133.11 0,157.75 z"
      id="path3437"
    />
    <path
      style="fill: url(#XMLID_29_-4); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 120.669,69.01 c 17.62,10.83 53.131,32.58 53.851,32.98 1.119,0.63 15.31,-23.93 18.53,-37.81 l -72.381,4.83 z"
      id="path3448"
    />
    <path
      style="fill: url(#XMLID_30_-6); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 63.83,121.66 30.56,58.96 c 18.07,-9.8 32.22,-21.74 45.18,-34.53 L 63.83,121.66 z"
      id="path3459"
    />
    <path
      style="fill: url(#XMLID_31_-6); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 13.35,133.19 -4.33,51.56 c 8.17,11.16 19.41,12.13 31.2,11.26 -8.53,-21.23 -25.57,-63.68 -26.87,-62.82 z"
      id="path3472"
    />
    <path
      style="fill: url(#XMLID_32_-0); fill-rule: evenodd"
      inkscape:connector-curvature="0"
      clip-rule="evenodd"
      d="m 135.9,17.61 60.71,8.52 C 193.37,12.4 183.42,3.54 166.46,0.77 L 135.9,17.61 z"
      id="path3485"
    />
  </svg>
</template>
